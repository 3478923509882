body[data-theme="light"] {
    @import '../src/scss/light-slider.scss';
 
    .slider-tab {
       background-color: #fe6601;
    }

    .language {
       background-color: #fe6601;
    }
    .parallax {
        background-image: url('https://gruecke2.github.io/garret-portfolio/assets/images/sunset_modern.png');
    }

}